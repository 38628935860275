<template>
  <div>
    <Modal :options="{width: '40vw', type: 'danger'}" @close="$emit('close')">
      <div class="title">Tomar Ticket</div>
      <div class="body">
        
        <p class="text-center">Estas por asignarte el ticket:
            <strong>{{ editar_ticket.id }}</strong>,
            este ticket te pertenecerá.<br/>¿Deseas continuar?
         </p>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="reasignar">Confirmar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">

import Modal from '@/components/Modal'
import apiClientes from '@/apps/clientes/api/clientes';
import config from "@/apps/clientes/config";

  export default {
    components: {
      Modal
    }
    ,props: {
      ticket: {
        type: Object,
      }
    },
    data(){
        return{
            editar_ticket:{
                usuario_activo:null
            },
        }
    }
    ,methods: {
      async reasignar() {
        try {
          this.editar_ticket.reasignar = config.asignacion.reasignar;
          // this.editar_ticket.reasignar = 1;

          // if(this.editar_ticket.estatus == 0)
          //   this.editar_ticket.estatus = 1;

          this.editar_ticket.cambio_operador = true;

          this.editar_ticket = (await apiClientes.editar_ticket(this.editar_ticket.id, this.editar_ticket)).data;
          this.editar_ticket.usuario_activo = this.editar_ticket.operador_id; 
          this.$emit('actualizar', this.editar_ticket);
          this.$emit('close');
          this.$helper.showMessage('Ticket reasignado','Se te ha asignado el ticket ID ' +this.editar_ticket.id,'success');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    },
    mounted(){
        this.editar_ticket = this.ticket;
    }
  }
</script>