<template>
    <div>
        <fieldset v-if="!nueva_direccion">
        <legend>Datos del cliente</legend>
                <div class="row form-group">
                    <label for="nombre" class="col-form-label col-sm-2">Folio Crédito</label>
                    <div class="col-sm-10"><input v-model="cliente.folio_credito" type="text" name="folio_credito" id="folio_credito" class="form-control" readonly></div>                                    
                </div>            
                <div class="row form-group">
                    <label for="nombre" class="col-form-label col-sm-2">Nombre completo</label>
                    <div class="col-sm-4"><input v-model="cliente.cliente_nombre" type="text" name="nombre" id="nombre" class="form-control" readonly></div>                 
                    <label for="email" class="col-form-label col-sm-2">Email</label>
                    <div class="col-sm-4"><input v-model="cliente.email" type="email" name="email" id="email" class="form-control" readonly></div>                      
                </div>                 
                <div class="row form-group">
                    <label for="telefono" class="col-form-label col-sm-2">Teléfono  <small class="text-muted">(Opcional)</small></label>
                    <div class="col-sm-4"><input v-model="cliente.telefono" type="text" name="telefono" id="telefono" class="form-control" readonly></div>  
                    <label for="direccion" class="col-form-label col-sm-2">Código Postal  <small class="text-muted">(Opcional)</small></label>
                    <div class="col-sm-4"><input v-model="cliente.cp" type="text" name="direccion" id="direccion" class="form-control" readonly></div>                                      
                </div>            
                <div class="row form-group">
                    <label for="calle" class="col-form-label col-sm-2">Calle  <small class="text-muted">(Opcional)</small></label>
                    <div class="col-sm-4"><input v-model="cliente.calle" type="text" name="direccion" id="direccion" class="form-control" readonly></div>    
                    <label for="numero_exterior" class="col-form-label col-sm-2">Número exterior  <small class="text-muted">(Opcional)</small></label>
                    <div class="col-sm-4"><input v-model="cliente.numero_exterior" type="text" name="direccion" id="direccion" class="form-control" readonly></div>  
                </div>            
                <div class="row form-group">
                    <label for="numero_interior" class="col-form-label col-sm-2">Número interior  <small class="text-muted">(Opcional)</small></label>
                    <div class="col-sm-4"><input v-model="cliente.numero_interior" type="text" name="direccion" id="direccion" class="form-control" readonly></div> 
                    <label for="colonia" class="col-form-label col-sm-2">Colonia  <small class="text-muted">(Opcional)</small></label>
                    <div class="col-sm-4"><input v-model="cliente.colonia" type="text" name="direccion" id="direccion" class="form-control" readonly></div>       
                </div>
                <div class="row form-group">
                    <label for="delegacion" class="col-form-label col-sm-2">Delegación ó municipio  <small class="text-muted">(Opcional)</small></label>
                    <div class="col-sm-4"><input v-model="cliente.delegacion_municipio" type="text" name="direccion" id="direccion" class="form-control" readonly></div>  
                    <label for="estado" class="col-form-label col-sm-2">Estado <small class=" text-muted">(Opcional)</small></label>
                    <div class="col-sm-4">
                        <select v-model="cliente.estado" name="direccion.estado" id="direccion.estado" class="form-control" disabled>
                            <option value="null">Selecciona un estado</option>
                            <option v-for="estado in estados" :key="estado.id" :value="estado.valor">{{ estado.nombre }}</option>
                        </select>
                    </div>  
                </div>
        </fieldset>   
        <fieldset v-if="!nueva_direccion">
            <legend>Solicitud del cliente</legend>
                <div class="row form-group">
                    <label for="descripcion" class="col-form-label col-sm-2">Descripción de la consulta/solicitud</label>
                    <div class="col-sm-10">
                        <textarea :disabled="cliente.origen==1" v-model="cliente.descripcion" name="descripcion" id="descripcion" cols="30" rows="5" class="form-control"></textarea>
                    </div>
                </div>              
        </fieldset>
        
        <Direccion v-if="nueva_direccion" :estados="estados" :ticket="ticket" :metodo_contacto="metodo_contacto"/>
    </div>  
</template>

<script>
import Direccion from "./Administrar/Contactos/Direccion";

export default {
    components:{
        Direccion
    },
    props:{
        ticket: {
            type: Object,
             default(){
                return{};
            }
        },
        estados:{
            type: Array,
            default(){
                return[];
            }
        },
        metodo_contacto:{
            type:Array,
             default(){
                return[];
            }
        },        
        nueva_direccion:null,
    },
    data(){
        return{
            cliente:{
                cliente_nombre:null,
                folio_credito:null,
                email:null,
                telefono:null,
                cp:null,
                calle:null,
                numero_exterior:null,
                numero_interior:null,
                colonia:null,
                delegacion_municipio:null,
                estado:null,
                descripcion:null,                       
                origen:null,                       
            }
        }
    },
    methods:{
        importar_busqueda(){
            
            this.cliente.cliente_nombre = this.ticket.cliente_nombre;
            this.cliente.folio_credito = this.ticket.folio_credito;
            this.cliente.email = this.ticket.email;
            this.cliente.telefono = this.ticket.telefono;       
            this.cliente.cp = this.ticket.cp;
            this.cliente.calle = this.ticket.calle;
            this.cliente.numero_exterior = this.ticket.numero_exterior;
            this.cliente.numero_interior = this.ticket.numero_interior;
            this.cliente.colonia = this.ticket.colonia;
            this.cliente.delegacion_municipio = this.ticket.delegacion_municipio;
            this.cliente.estado = this.ticket.estado;
            this.cliente.descripcion = this.ticket.descripcion;
            this.cliente.origen = this.ticket.origen;
        },
        actualizar_cliente(){
            this.$emit('actualizar_cliente', {cliente:this.cliente});
        }
    },
    watch:{
        ticket:{
            handler(){
                this.importar_busqueda();
            },
            deep: true,
        },
        cliente:{
            handler(){
                this.actualizar_cliente();
            },
            deep:true,
        },
        // cerrar_formulario(val){
        //     this.$log.info('cerrar_formulario', this.cerrar_formulario);
        // }
    },
    mounted(){
        this.importar_busqueda();
    },

}
</script>

<style>

</style>