<template>
  <div>
    <template v-if="ticket.operador_nombre!==null">
        <fieldset>
            <legend>Este ticket esta siendo atendido por: </legend>
                <div class="row form-group">
                    <label for="nombre" class="col-form-label col-sm-2">Usuario</label>
                        <div class="col-sm-10"><input v-model="ticket.operador_nombre" type="text" name="nombre" id="nombre" class="form-control" readonly></div>
                </div>                
        </fieldset>
    </template>
    <template v-else>
        <div class="text-center">
            <h1>¡Adelante, puedes tomar el ticket!</h1>
        </div>
    </template>
  </div>
</template>
<script>
export default {
    props:{
        ticket:{
            type:Object
        }
    },
}
</script>

<style>

</style>