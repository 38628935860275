<template>
  <div>
    <fieldset>
        <legend>Documentos Cargados</legend>
            <template v-if="documentos.length > 0">
                <div class="col-sm-6"><input v-show="false" type="file" @change="file_input" class="form-control-file" id="carga_documentos" ref="select_file"></div>
                <div class="col-sm-4 offset-8 mb-4"><button class="btn btn-block btn-primary" @click="seleccionar_archivo">Nuevo Documento</button></div>     
                <div class="row form-group">                
                    <div v-for=" documento in documentos" :key="documento.id" class="col-sm-4">
                        <div class="card text-center borde-card border mb-3">
                            <div class="card-body text-left">
                                <span><strong> Nombre: </strong>{{ documento.nombre_original }} </span> <br> 
                                <span><strong> Fecha de creacion: </strong>  {{ $moment(documento.created_at).locale('es').format('DD MMM YYYY hh:mm:ss')  }} <br></span>
                                <a href="#" class="badge badge-primary mt-4 mr-2" @click="descargar_documento(documento)">Descargar Archivo</a>
                                <a href="#" class="badge badge-danger mt-4" @click="eliminar_documento(documento)">Eliminar</a>
                            </div>
                        </div>
                    </div>
                </div>  
            </template>
            <template v-else>
                <div class="text-center">
                <div class="col-sm-6"><input v-show="false" type="file" @change="file_input" class="form-control-file" id="carga_documentos" ref="select_file"></div>
                <div class="col-sm-4 offset-8 mb-4"><button class="btn btn-block btn-primary" @click="seleccionar_archivo">Nuevo Documento</button></div>     
                    <h3>No hay documentos cargados</h3>
                </div>                
            </template>            
    </fieldset>

    <Eliminar v-if="modal_eliminar_documento==true" :eliminar_archivo="eliminar_archivo" @actualizar="actualizar_documentos" @close="modal_eliminar_documento=false"/>

  </div>
</template>

<script>
import apiClientes from '@/apps/clientes/api/clientes';
import Eliminar from "./Administrar/Documentos/Eliminar";
import config from "@/apps/clientes/config";


export default {
    components:{
        Eliminar
    },
    props:{
        ticket:null,
    },
    data(){
        return{
            file:null,
            documentos:[],
            eliminar_archivo:null,
            modal_eliminar_documento:false,
            mensaje:null,
        }
    },
    methods:{
        file_input(event){
            this.file = event.target.files[0];
        },
        async seleccionar_archivo(){
            this.$refs.select_file.click();
        },
        async cargar_documento(){
            try {
                if(this.file === null)
                    return this.$helper.showMessage('Error','Tienes que seleccionar un archivo', 'error','alert');

                let formData = new FormData();
                    formData.append('archivo',this.file);       
                    formData.append('ticket',JSON.stringify(this.ticket));       
                    formData.append('origen', config.documentos.origen_administrador);       

                await apiClientes.cargar_documento_tickets(this.ticket.id, formData);
                this.file = null;
                this.$helper.showMessage('¡Listo!','La carga de documentos ha sido exitosa','success');
                this.$emit('actualizar', this.ticket.id);

            } catch (error) {
                this.$log.info('error', error);
                this.$helper.showAxiosError(error,'Error');

            }            
        },
        mostrar_documentos(){
            this.documentos = [];
            this.ticket.documentos.forEach(async archivo => {
                let res = (await apiClientes.listar_documento_tickets(archivo.id)).data;
                    archivo.file = URL.createObjectURL(new Blob([res]));
                    this.documentos.push(archivo);                                
            });
            // this.$log.info('mostrar documentos', this.documentos);
        },
        async descargar_documento(documento){
            try {
                let res = (await apiClientes.descarga_documento_tickets(documento.id)).data;

                let url = window.URL.createObjectURL(new Blob([res]));
                let link = document.createElement('a');
                link.href = url;
                link.setAttribute('download',documento.nombre_original);
                document.body.appendChild(link);
                link.click();

            }catch(e) {
                this.$log.info('error',e);
                this.$helper.showAxiosError(e,'Error');
            }
        },
        actualizar_documentos(){
            this.$emit('actualizar', this.ticket.id);
        },
        eliminar_documento(data){
            this.eliminar_archivo = data;
            this.modal_eliminar_documento = true;         
        },
    },
    mounted(){
        if(this.ticket.documentos)
            this.mostrar_documentos();
    },
    watch:{
        ticket(){
            if(this.ticket.documentos)
                this.mostrar_documentos();
        },
        file(){
            if(this.file!==null)
                this.cargar_documento();
        }
    }

}
</script>

<style>

</style>