
<template>
  <div>
    <Modal :options="{width: '45vw', close: true}" @close="$emit('close')" >
        <div class="title"> Agregar un nuevo motivo </div>
        <div class="body">
            <div class="row form-group">
                <label for="calle" class="col-form-label col-sm-2"> Nombre </label>
                <div class="col-sm-10"><input v-model="opcion.nombre" type="text" name="direccion" id="direccion" class="form-control"></div>
            </div>                                               
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <template>
                <button type="button" class="btn btn-primary mr-2" @click="agrear_motivo"> Agregar Motivo </button>
                <button type="button" class="btn btn-danger mr-2" @click="$emit('close')"> cancelar </button>
              </template>
            </div>
          </div>
        </div>        
    </Modal>
  </div>
</template>

<script>

import Modal from '@/components/Modal';
import config from "@/apps/clientes/config";
import apiClientes from '@/apps/clientes/api/clientes';


export default {
    components:{
        Modal
    },
    data(){
        return{
            opcion:{
                nombre:null,
                valor:null,
                codigo:null
            }
        }
    },
    methods:{
        async agrear_motivo(){
            if(this.opcion.nombre==null || this.opcion.nombre=='')
                return this.$helper.showMessage('Error', 'Debes indicar el nombre del nuevo motivo para ser agregado', 'error', 'alert');

            try {
                this.opcion.valor = this.$helper.normalizar(this.opcion.nombre);
                this.opcion.codigo = this.$helper.normalizar(this.opcion.nombre);

                await apiClientes.catalogo_agregar_opcion(config.catalogos.motivos_ticket, this.opcion);
                this.$emit('actualizar');
                this.$helper.showMessage('Motivo agregado','El motivo ha sido agregado con éxito','success');
                this.$emit('close');

            } catch (e) {
                this.$log.info('error', e);
                this.$helper.showAxiosError(e,'Error');
            }

        }
    }
    
}
</script>

<style>

</style>