<template>
  <div>
    <Modal :options="{width: '97vw',  close: true}" @close="$emit('close')" >
        <div class="title"> Editar Ticket ID {{ editar_ticket.id }} </div>
        <div class="body">
          <div class="row">
            <div class="col-sm-8">
              <template v-if="!nueva_direccion">
                <ul class="nav nav-tabs mb-4">
                  <li class="nav-item"><span :class="'nav-link'+(tabs_info == 1 ? ' active' : '')" @click="tabs_info =1">Asignación del Ticket</span></li>
                  <li class="nav-item"><span :class="'nav-link'+(tabs_info == 2 ? ' active' : '')" @click="tabs_info =2">Información del Ticket</span></li>
                  <template v-if="editar_ticket.subarea!==null">
                    <li class="nav-item"><span :class="'nav-link'+(tabs_info == 3 ? ' active' : '')" @click="tabs_info =3">Documentos</span></li>
                  </template>
                </ul>
              </template>
                
                <Asignado
                  v-if="tabs_info==1" :ticket="editar_ticket"
                />

                <template v-if="editar_ticket.subarea!==null && editar_ticket.area!==null && editar_ticket.motivo!==null && editar_ticket.tipo_solicitud!==null">
                  <Formulario 
                    v-if="tabs_info==2"
                    :ticket="editar_ticket"
                    :estados="estados"
                    :nueva_direccion="nueva_direccion"
                    :metodo_contacto="metodo_contacto"
                    @actualizar_cliente="actualizar_solicitud"
                  />

                  <Informacion 
                    v-if="importar_nuevo_contacto"
                    :informacion="informacion_busqueda"
                    :metodo_contacto="metodo_contacto"
                    @close="importar_nuevo_contacto=false"
                    @informacion="importar_nueva_informacion"
                    @nueva_direccion="nueva_direccion=true"
                  />

                  <Documentos
                    v-if="tabs_info==3"
                    :ticket="editar_ticket"
                    @actualizar="actualizar_ticket"
                  />
                </template>

                <Clasificacion 
                  v-if="!nueva_direccion && tabs_info==2"
                  :tipos_solicitud="tipos_solicitud"
                  :motivos_ticket="motivos_ticket" 
                  :areas_bpb="areas_bpb"
                  :subareas_bpb="subareas_bpb"
                  :ticket="editar_ticket"
                  @actualizar_catalogos="obtener_catalogos"
                  @actualizar_clasificacion="actualizar_clasificacion"
                />
            </div>
            <div class="col-sm-4 margin-comentarios">
              <Comentarios :ticket="editar_ticket" @actualizar="actualizar_ticket" :nuevo_comentario="comentario"/>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <template v-if="nueva_direccion && tabs_info==2">
                  <div class="col-sm-2"><button type="button" class="btn  btn-block btn-info" @click="mostrar_nueva_info"> Importar Información </button></div>
                  <!-- <div class="col-sm-3 offset-7"><button :disabled="editar_ticket.estatus==2" type="button" class="btn btn-dark btn-block" @click="modal_comentario=true">Agregar Comentario</button></div> -->
                  <div class="col-sm-3 offset-7"><button  type="button" class="btn btn-dark btn-block" @click="modal_comentario=true">Agregar Comentario</button></div>
            </template>            
            <template v-if="!nueva_direccion">
              <template v-if="tabs_info==1 && usuario_activo !== editar_ticket.operador_id">
                <div class="col-sm-2"><button type="button" class="btn btn-info btn-block" @click="asignar_ticket">Tomar Ticket</button></div>
              <!-- <div class="col-sm-3 offset-7"><button :disabled="editar_ticket.estatus==2" type="button" class="btn btn-dark btn-block" @click="modal_comentario=true">Agregar Comentario</button></div> -->
                <div class="col-sm-3 offset-7"><button  type="button" class="btn btn-dark btn-block" @click="modal_comentario=true">Agregar Comentario</button></div>
              </template>
              <template v-else-if="tabs_info==1 && usuario_activo == editar_ticket.operador_id">
              <!-- <div class="col-sm-3 offset-9"><button :disabled="editar_ticket.estatus==2" type="button" class="btn btn-dark btn-block" @click="modal_comentario=true">Agregar Comentario</button></div> -->
                <div class="col-sm-3 offset-9"><button  type="button" class="btn btn-dark btn-block" @click="modal_comentario=true">Agregar Comentario</button></div>
              </template>              
              <template v-if="tabs_info==2">
                <template v-if="editar_ticket.estatus==1">
                  <div class="col-sm-3"><button :disabled="editar_ticket.subarea==null" type="button" class="btn btn-danger btn-block" @click="modal_ticket_resuelto=true">Marcar ticket como resuelto</button></div>
                  <div class="col-sm-3"><button :disabled="editar_ticket.subarea==null" type="button" class="btn btn-info btn-block" @click="nueva_direccion=true">Actualizar Dirección o Contacto</button></div>
                  <div class="col-sm-2"><button type="button" class="btn btn-primary btn-block" @click="guardar">Guardar</button></div>
                  <!-- <div class="col-sm-3 offset-1"><button :disabled="editar_ticket.estatus==2" type="button" class="btn btn-dark btn-block" @click="modal_comentario=true">Agregar Comentario</button></div> -->
                  <div class="col-sm-3 offset-1"><button  type="button" class="btn btn-dark btn-block" @click="modal_comentario=true">Agregar Comentario</button></div>
                </template>
                <template v-else>
                  <div class="col-sm-3"><button :disabled="editar_ticket.subarea==null" type="button" class="btn btn-info btn-block" @click="nueva_direccion=true">Actualizar Dirección o Contacto</button></div>
                  <div class="col-sm-2"><button type="button" class="btn btn-primary btn-block" @click="guardar">Guardar</button></div>
                  <!-- <div class="col-sm-3 offset-4"><button :disabled="editar_ticket.estatus==2" type="button" class="btn btn-dark btn-block" @click="modal_comentario=true">Agregar Comentario</button></div>                   -->
                  <div class="col-sm-3 offset-4"><button type="button" class="btn btn-dark btn-block" @click="modal_comentario=true">Agregar Comentario</button></div>                  
                </template>
              </template>
              <template v-if="tabs_info==3">
              <!-- <div class="col-sm-3 offset-9"><button :disabled="editar_ticket.estatus==2" type="button" class="btn btn-dark btn-block" @click="modal_comentario=true">Agregar Comentario</button></div> -->
                <div class="col-sm-3 offset-9"><button  type="button" class="btn btn-dark btn-block" @click="modal_comentario=true">Agregar Comentario</button></div>
              </template>
            </template>
          </div>
        </div>
    </Modal>

    <Reasignar v-if="modal_reasignar" :ticket="editar_ticket" @close="modal_reasignar=false" @actualizar="acutalizar_asignacion" />
    <AgregarComentario v-if="modal_comentario==true" @close="modal_comentario=false" @comentario="pasar_comentario"/>
    <TicketResuelto v-if="modal_ticket_resuelto" :ticket="editar_ticket" @close="modal_ticket_resuelto=false" @actualizar="actualizar_ticket"/>

  </div>
</template>

<script>
import apiClientes from '@/apps/clientes/api/clientes';
import config from "@/apps/clientes/config";

import Modal from '@/components/Modal';
import Formulario from "./Formulario";
import Informacion from './Informacion';
import Clasificacion from './Clasificacion';
import Asignado from "./Asignado";
import Reasignar from "./Reasignar";
import Documentos from "./Documentos";
import Comentarios from "./Comentarios";
import AgregarComentario from "./Administrar/Comentarios/Agregar";
import TicketResuelto from "./TicketResuelto";


export default {
  components:{
    Modal,
    Formulario,
    Informacion,
    Clasificacion,
    Asignado,
    Reasignar,
    Documentos,
    Comentarios,
    AgregarComentario,
    TicketResuelto
  },
  props:{
    ticket:{
      type:Object,
    }
  },
  data(){
    return{
      editar_ticket:{
        id:null,
        folio_credito:null,
        cliente_id: null,
        cliente_nombre: null,
        email: null,
        telefono: null,
        cp: null,
        calle: null,
        numero_exterior: null,
        numero_interior: null,
        colonia: null,
        delegacion_municipio: null,
        estado: null,
        descripcion: null,
        tipo_solicitud: null,
        motivo: null,
        area: null,
        subarea: null,
        operador_id:null,
        reasignar:false,
        estatus:null,
        documentos:null,
      },
      tabs_info:1,
      nueva_direccion:false,
      importar_nuevo_contacto:false,
      estados:[],
      metodo_contacto:[],
      informacion_busqueda:[],
      tipos_solicitud:[],
      motivos_ticket:[],
      areas_bpb:[],
      subareas_bpb:[],
      usuario_activo:null,
      modal_reasignar:false,
      comentario:null,
      modal_comentario:false,
      modal_ticket_resuelto:false,
    }
  },
  beforeMount(){
    this.obtener_catalogos();
    this.editar_ticket = this.ticket;
    this.editar_ticket.operador_id = Number(this.editar_ticket.operador_id);
    this.usuario_activo = this.$auth.getUser().id;
    this.$log.info('datos', this.editar_ticket);
  },  
  methods:{
    async guardar(){
      if(this.editar_ticket.cliente_id == null || this.editar_ticket.email == null || this.editar_ticket.descripcion == null || this.editar_ticket.tipo_solicitud == null || this.editar_ticket.motivo == null || this.editar_ticket.area == null || this.editar_ticket.subarea == null)
        return this.$helper.showMessage('Error','Los siguientes datos no pueden quedar vacíos: nombre, email, descripción y clasificación','error','alert');
      try {
        this.editar_ticket.reasignar = config.asignacion.ya_asignado;
        this.editar_ticket = (await apiClientes.editar_ticket(this.editar_ticket.id, this.editar_ticket)).data;
        this.$emit('actualizar');
        this.$helper.showMessage('Ticket acutalizado','Se ha acutalizado la información con éxito','success');
      } catch (e) {
        this.$log.info(e);
        this.$helper.showAxiosError(e, 'error');
      }
    },
    async obtener_catalogos(){
      try {
        this.estados = (await apiClientes.listar_catalogos(config.catalogos.estados)).data.opciones;
        this.metodo_contacto = (await apiClientes.listar_catalogos(config.catalogos.metodo_contacto)).data.opciones;
        this.tipos_solicitud = (await apiClientes.listar_catalogos(config.catalogos.tipo_solicitud)).data.opciones;
        this.motivos_ticket = (await apiClientes.listar_catalogos(config.catalogos.motivos_ticket)).data.opciones;
        this.areas_bpb = (await apiClientes.listar_catalogos(config.catalogos.areas_bpb)).data.opciones;
        this.subareas_bpb = (await apiClientes.listar_catalogos(config.catalogos.subareas_bpb)).data.opciones;        
      } catch (error) {
        this.$log.info('error',error);
        this.$helper.showAxiosError(error,'Error');             
      }
    },  
    async mostrar_nueva_info(){
      try {
        this.nueva_direccion = false;
        this.informacion_busqueda = (await apiClientes.obtener_informacion_cliente(this.ticket.cliente_id)).data;
        // this.ticket.cliente_nombre = this.ticket.cliente_nombre;
        this.importar_nuevo_contacto= true;  
      } catch (error) {
        this.$log.info('error',error);
        this.$helper.showAxiosError(error,'Error');   
      }      
    },
    importar_nueva_informacion(data){  
      if(data.email !== null)
        this.editar_ticket.email = data.email.valor;
      
      if(data.telefono !==null) 
        this.editar_ticket.telefono = data.telefono.valor;  
      
      if(data.folio_credito !== null)
        this.ticket.folio_credito = data.folio_credito;        
      
      if(data.direccion !== null){
        this.editar_ticket.cp = data.direccion.cp;
        this.editar_ticket.calle = data.direccion.calle;
        this.editar_ticket.numero_exterior = data.direccion.no_exterior;
        this.editar_ticket.numero_interior = data.direccion.no_interior;
        this.editar_ticket.colonia = data.direccion.colonia;
        this.editar_ticket.delegacion_municipio = data.direccion.delegacion;
        this.editar_ticket.estado = this.$helper.normalizar(data.direccion.estado);
      }
    },
    actualizar_clasificacion(data){
      this.editar_ticket.tipo_solicitud = data.tipo_solicitud;
      this.editar_ticket.motivo = data.motivo;
      this.editar_ticket.area = data.area;
      this.editar_ticket.subarea = data.subarea;
    },
    actualizar_solicitud(data){
      this.editar_ticket.descripcion = data.cliente.descripcion;
    },
    acutalizar_asignacion(data){
      this.editar_ticket = data
      this.usuario_activo = data.usuario_activo
      this.$emit('actualizar');
    },
    async asignar_ticket(){
      try {
        if(this.editar_ticket.operador_id == 0){
          this.editar_ticket.cambio_operador = true;
          this.editar_ticket.reasignar = config.asignacion.asignar;

          this.editar_ticket = (await apiClientes.editar_ticket(this.editar_ticket.id, this.editar_ticket)).data;
          this.$emit('actualizar');
          this.$helper.showMessage('Ticket asignado','Se te ha asignado el ticket ID ' +this.editar_ticket.id,'success');
        }else{
          this.modal_reasignar = true;
        }
      } catch (error) {
        this.$log.info(e);
        this.$helper.showAxiosError(e, 'error');        
      }
    },
    async actualizar_ticket(id){
      try {
        this.editar_ticket = (await apiClientes.listar_ticket_filtro('id', id)).data[0];
        this.editar_ticket.operador_id = Number(this.editar_ticket.operador_id);
        this.comentario = null;
        this.$emit('actualizar');  
      } catch (error) {
        this.$log.info('error',error);
        this.$helper.showAxiosError(error,'Error');        
      }      
    },
    pasar_comentario(data){
      this.comentario = data;
      this.modal_comentario = false;
    }    
  },

}
</script>

<style lang="scss">

.margin-comentarios{
  margin-top: 65px;
}

</style>

             