<template>
  <div>
    <Modal :options="{width: '40vw'}" @close="$emit('close')">
      <div class="title">Elige un Crédito</div>
      <div class="body">
        <div class="row form-group">
            <div class="col-sm-10 texto-menor offset-1">
                <div class="row header">
                    <div class="col-sm-10">Folio de Crédito</div>
                    <div class="col-sm-2">Seleccionar</div>
                </div>
                <template v-if="creditos.length > 0">
                <div v-for="credito in creditos" :key="credito.id" class="row row_observation">
                    <div  class="col-sm-9">
                        <strong>Folio: </strong> {{ credito.folio }}
                    </div>
                    <div class="col-sm-3 text-center"> <input v-model="credito_seleccionado" class="form-check-input" type="radio" :id="credito.id" :value="credito.folio"/></div>
                </div>
                </template>
                <div v-else class="row">
                    <div class="col-sm-12">No hay créditos</div>
                </div>
            </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="seleccionar_credito">Confirmar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

export default {
  props:{
    creditos:null,
  },
  components:{
    Modal
  },
  data(){
    return{
        credito_seleccionado:null,
    }
  },
  methods:{
    seleccionar_credito(){
        this.$emit('credito', this.credito_seleccionado);
        this.$emit('close');
    }
  },
//   mounted(){
//     this.$log.info('creditos ', this.creditos);
//   }

}
</script>

<style lang="scss" scoped>
  .file_list_container {
    overflow: auto;

    .file_list {
      button {
        margin: 3px;
      }
    }

    .file_list:nth-child(2n+1) {
      background-color: #E6E6E6;
    }
  }

  .header {
    background-color: #4D4D4D;
    color: #fff;
    padding: 5px 3px;
  }

  .texto-menor {
    font-size: 0.8em !important;

    button {
      font-size: 0.8em !important;
    }
  }

  .row_observation {
    div {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .row_observation:nth-child(2n+1) {
    background-color: #E6E6E6;
  }
</style>    