<template>
  <div>
    <div class="row p-3">
      <h1 class="col-sm-12 text-right">Buzón de ayuda</h1> 
    </div>
    <div class="col-sm-12 ">
      <div class="text-right">
        <div class="col-sm-3 offset-9">
          <div class="input-group mb-3">
            <input v-model="buscar" type="text" class="form-control" placeholder="Ticket, nombre, etc">
            <div class="input-group-append">
              <button class="btn btn-primary">Buscar</button>
            </div>
          </div>
        </div>        
        <div class="btn-group" role="group">
          <button class="btn btn-primary " @click="obtener_tickets">Recargar</button>
          <button class="btn btn-success" @click="modal_formulario=true">Nuevo ticket</button>
            <div class="btn-group" role="group">
              <select v-model="ticket_tipo" name="tipo_etapa" id="tipo_etapa" class="form-control">
                <option value="a">Ver todos los tickets</option>
                <option value="0">Ver tickets no asignados</option>
                <option value="1">Ver tickets en proceso de resolución</option>
                <option value="2">Ver tickets en espera de respuesta del cliente</option>
                <option value="3">Ver tickets resueltos</option>
                <option value="4">Ver tickets cerrados</option>
              </select>
            </div>
        </div>
      </div>
      <div v-if="tickets_paginados.length == 0">
        <div class="col-sm-12 text-center mt-5"> <h2>¡No hay Tickets!</h2>  </div>
      </div>
      <div  v-else class="row mt-4">
          <Card v-for="ticket in tickets_paginados" :key="ticket.id" :ticket="ticket" @detalle="ver_ticket"/>
          <div v-if="paginas_mostrar.length > 1" class="row col-sm-12 mt-5">
            <nav>
              <ul class="pagination">
                <li class="page-item"><a href="#" class="page-link" @click="pagina_anterior">Anterior</a></li>
                <div v-for="num_pagina in paginas_mostrar" :key="num_pagina.id">
                  <li :class="'page-item'+(num_pagina == pagina_actual ? ' pagina_actual' : '')"><a href="#" class="page-link" @click="pagina_actual = num_pagina">{{num_pagina}}</a></li>
                </div>
                <li class="page-item"><a class="page-link" href="#" @click="pagina_siguiente">Siguiente</a></li>
              </ul>
            </nav>
          </div>
      </div>
    </div>
      <EditarTicket v-if="editar_ticket_modal" @close="editar_ticket_modal=false" :ticket="ticket"  @actualizar="obtener_tickets"/>
      <Ticket v-if="modal_formulario" @close="modal_formulario=false" @obtener_tickets="obtener_tickets"/>
  </div>
</template>

<script>
  import Ticket from './Buzon/Ticket'
  import Card from "./Buzon/Card";
  import EditarTicket from './Buzon/EditarTicket'
  import apiClientes from '@/apps/clientes/api/clientes';

  export default {
    components: {
      Ticket, EditarTicket, Card
    },
    data() {
      return {
        modal_formulario: false,
        editar_ticket_modal: false,
        tickets:[],
        ticket:null,
        ticket_tipo:'a',
        pagina_actual:1,
        por_pagina:12,
        buscar:null,
      }
    },
    mounted(){
      this.obtener_tickets();
    },
    methods:{
      async obtener_tickets(){
       try {
          this.tickets = (await apiClientes.listar_tickets()).data;
          this.$log.info('todos los tickets ', this.tickets);
        } catch (error) {
          this.$log.info('error',error);
          this.$helper.showAxiosError(error,'Error');
        }        
      },
      async ver_ticket(data){
        this.ticket = (await apiClientes.listar_ticket_filtro('id', data.id)).data[0];
        this.editar_ticket_modal = true;
        // this.$log.info('detalles ticket', data);
      },
      pagina_anterior(){
        if(this.pagina_actual > 1)
          this.pagina_actual -= 1;
      },
      pagina_siguiente(){
        if(this.pagina_actual < this.$paginator.total_pages(this.tickets_visibles, this.por_pagina))
          this.pagina_actual += 1;
      }
    },
    computed:{
      tickets_visibles(){
        let tickets = [];
        if(this.ticket_tipo != 'a')
          this.tickets.forEach(ticket => {
            if(ticket.estatus == this.ticket_tipo)
              tickets.push(ticket);
          });
        else
          tickets = this.tickets;

        if(this.buscar && this.buscar!=''){
          let busqueda = [];
          this.tickets.forEach(ticket => {
            if(Object.values(ticket).toString().indexOf(this.buscar) != -1)
              busqueda.push(ticket);
          });
          tickets = busqueda;
        }
        return tickets;
      },
      tickets_paginados() {
        return this.$paginator.paginate_data(this.tickets_visibles, this.pagina_actual, this.por_pagina, 'desc');
      },
      paginas_mostrar() {
        return this.$paginator.paginate_navigation(this.tickets_visibles, this.pagina_actual, this.por_pagina, 3);
      },            
    },
    watch: {
      ticket_tipo() {
        this.pagina_actual = 1;
      }
    }
  }
</script>


<style lang="scss" scope>


  .pagina_actual {
    a {
      background-color: #C4E5C5;
    }
  }
</style>