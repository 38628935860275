<template>
  <div>
    <Modal :options="{width: '40vw', type: 'danger'}" @close="$emit('close')">
      <div class="title">Marcar ticket como resuelto</div>
      <div class="body">
        <p class="text-center">Estas por marcar como resuelto:
            <strong>{{ ticket.id }}</strong>,
            esta acción no puede deshacerse.<br/>¿Deseas continuar?
        </p>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="confirmar">Confirmar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import apiClientes from '@/apps/clientes/api/clientes';
import config from "@/apps/clientes/config";

export default {
  props:{
    ticket:null,
  },
  components:{
    Modal
  },
  methods:{
    async confirmar(){
      try {
        this.ticket.estatus = config.estatus_ticket.resuelto;
        this.ticket.cambio_estatus = true;
        await apiClientes.editar_ticket(this.ticket.id, this.ticket);
        this.$emit('actualizar', this.ticket.id);        
        this.$helper.showMessage('¡Ticket resuelto!','El ticket ID ' +this.ticket.id +' ha sido resuelto','success');
        this.$emit('close');
      } catch (error) {
        this.$log.info('error',error);
        this.$helper.showAxiosError(error,'Error');        
      }


    }
  }

}
</script>

<style>

</style>