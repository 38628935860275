<template>
    <div class="col-sm-3 mb-2">
        <div :class="'card text-center borde-card border'+border_color">
            <div :class="'card-header text-left'+ card_head_color" >
                <strong> Ticket ID. </strong> {{ editar_ticket.id }}
            </div>
            <div class="card-body text-left">
                <span><strong>Cliente: </strong>  {{ editar_ticket.cliente_nombre }}</span><br> 
                <template v-if="editar_ticket.estatus!==0">
                    <span><strong>Subarea: </strong>  {{ editar_ticket.subarea }}</span><br> 
                </template>
                <span> <strong>Fecha de creacion: </strong>  {{ $moment(editar_ticket.created_at).locale('es').format('DD MMM YYYY hh:mm:ss')  }} </span><br>
                <span> <strong>No. Crédito:</strong> {{  editar_ticket.folio_credito  }} </span> <br>
                <span> <strong>Origen:</strong> {{  origen  }} </span> <br>
                <span> <strong>Estatus:</strong> {{  estatus_texto  }} </span>
            </div>
            <div class="card-footer text-muted text-right">
                <button type="button" class="btn btn-outline-secondary" @click="ver_ticket">Ver Ticket</button>
            </div>
        </div> 
    </div>
</template>

<script>
export default {
    props:{
        ticket:null,
    },
    data(){
        return{
            editar_ticket:{
                estatus:null,
            }
        }
    },
    methods:{
        ver_ticket(){
            this.$emit('detalle', this.editar_ticket);
        }
    },
    computed:{
        estatus_texto(){
            switch (this.editar_ticket.estatus) {
                case 0:
                    return 'No Asignado';
                case 1:
                    return 'En proceso de resolución';
                case 2:
                    return 'En espera de respuesta del cliente';
                case 3:
                    return 'Resuelto';
                case 4:
                    return 'Cerrado';
            }
        },
        card_head_color(){
            switch (this.editar_ticket.estatus) {
                case 0:
                    return ' text-danger';
                case 1:
                    return ' text-primary';
                case 2:
                    return ' text-info';
                case 3:
                    return ' text-success';
                case 4:
                    return ' text-dark';
            }            
        },
        border_color(){
            switch (this.editar_ticket.estatus) {
                case 0:
                    return ' border-danger';
                case 1:
                    return ' border-primary';
                case 2:
                    return ' border-info';
                case 3:
                    return ' border-success';
                case 4:
                    return ' border-dark';
            }            
        },
        origen()        {
            switch(this.editar_ticket.origen){
                case 0:
                    return 'Administrador';
                case 1:
                    return 'App de Clientes';
            }
        }
    },
    beforeMount(){
        this.editar_ticket = this.ticket;
    },
    watch:{
        ticket(){
            this.editar_ticket = this.ticket;
        }
    }
}
</script>

<style lang="scss" scoped>
.borde-card{
    border-width:2px !important;
}
</style>