<template>
  <div>
    <Modal :options="{width: '90vw', close: true}" @close="$emit('close')">
        <div class="title"> {{ !nueva_direccion ? 'Nuevo ticket' : 'Agregar, editar, o eliminar un método de contacto' }} </div>
        <div class="body">

          <Buscar 
           v-if="!nueva_direccion"
           :ticket="ticket"
           :metodo_contacto="metodo_contacto"
           @cliente="importar_cliente"
           @actualizar="importar_informacion_cliente"
           @nueva_direccion="nueva_direccion=true"
          />

          <Formulario 
            :ticket="ticket"
            :estados="estados"
            :nueva_direccion="nueva_direccion"
            :metodo_contacto="metodo_contacto"
            @actualizar_cliente="actualizar_solicitud"
          />

          <Clasificacion 
            v-if="!nueva_direccion"
            :tipos_solicitud="tipos_solicitud"
            :motivos_ticket="motivos_ticket" 
            :areas_bpb="areas_bpb"
            :subareas_bpb="subareas_bpb"
            :ticket="ticket"
            @actualizar_catalogos="obtener_catalogos"
            @actualizar_clasificacion="actualizar_clasificacion"
          />

          <Informacion 
            v-if="importar_nuevo_contacto"
            :informacion="informacion_busqueda"
            :metodo_contacto="metodo_contacto"
            @close="importar_nuevo_contacto=false"
            @informacion="importar_nueva_informacion"
            @nueva_direccion="nueva_direccion=true"
          />
          
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <template v-if="nueva_direccion">
                <button type="button" class="btn btn-primary mr-2" @click="mostrar_nueva_info"> Importar Información </button>
              </template>
              <template v-if="!nueva_direccion">
                <button type="button" class="btn btn-primary mr-2" @click="crear_ticket"> Crear Ticket </button>
                <button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button>
              </template>
            </div>
          </div>
        </div>
    </Modal>
  </div>
</template>

<script>

import apiClientes from '@/apps/clientes/api/clientes';
import config from "@/apps/clientes/config";
import Modal from '@/components/Modal';
import Buscar from './Buscar';
import Formulario from "./Formulario";
import Clasificacion from './Clasificacion';
import Informacion from './Informacion'


export default {
  components: {
    Modal, Buscar, Formulario, Clasificacion, Informacion
  }
  ,data() {
    return {
      ticket: {
        id:null,
        folio_credito:null,
        cliente_id: null,
        cliente_nombre: null,
        email: null,
        telefono: null,
        cp: null,
        calle: null,
        numero_exterior: null,
        numero_interior: null,
        colonia: null,
        delegacion_municipio: null,
        estado: null,
        descripcion: null,
        tipo_solicitud: null,
        motivo: null,
        area: null,
        subarea: null,
        origen:0        
      },
      estados:[],
      metodo_contacto:[],
      nueva_direccion:false,
      importar_nuevo_contacto:false,
      informacion_busqueda:[],
      tipos_solicitud:[],
      motivos_ticket:[],
      areas_bpb:[],
      subareas_bpb:[],
    }
  },
  beforeMount() {
    this.obtener_catalogos(); // en produccion marca que las variables 'undefined'
  },
  methods: {
    async crear_ticket(){
      try {
        this.$log.info('crear ticket ', this.ticket);
        // if(this.ticket.id == null){
          if(this.ticket.cliente_id == null || this.ticket.email == null || this.ticket.descripcion == null || this.ticket.tipo_solicitud == null || this.ticket.motivo == null || this.ticket.area == null || this.ticket.subarea == null)
              return this.$helper.showMessage('Error','Debes cumplir con los siguientes datos antes de crear el ticket: nombre, email, descripción, clasificación y asignación','error','alert');

          this.ticket = (await apiClientes.crear_ticket(this.ticket)).data;
          this.$helper.showMessage('Ticket creado','Ticket creado con éxito','success');
          // this.$log.info('ticket nuevo');
          this.$emit('close');
          this.$emit('obtener_tickets');
          // this.$log.info(this.ticket);
        // }

      } catch (error) {
        this.$log.info('error',error);
        this.$helper.showAxiosError(error,'Error');
      }
    },
    async obtener_catalogos(){
      try {
        this.estados = (await apiClientes.listar_catalogos(config.catalogos.estados)).data.opciones;
        this.metodo_contacto = (await apiClientes.listar_catalogos(config.catalogos.metodo_contacto)).data.opciones;
        this.tipos_solicitud = (await apiClientes.listar_catalogos(config.catalogos.tipo_solicitud)).data.opciones;
        this.motivos_ticket = (await apiClientes.listar_catalogos(config.catalogos.motivos_ticket)).data.opciones;
        this.areas_bpb = (await apiClientes.listar_catalogos(config.catalogos.areas_bpb)).data.opciones;
        this.subareas_bpb = (await apiClientes.listar_catalogos(config.catalogos.subareas_bpb)).data.opciones;        
      } catch (error) {
        this.$log.info('error',error);
        this.$helper.showAxiosError(error,'Error');        
      }
    },
    importar_cliente(data){
      this.$log.info('cliente rapido', data);
      this.ticket.folio_credito = data.folio_credito;
      this.ticket.cliente_id = data.id;
      this.ticket.cliente_nombre = data.nombre; 
      this.ticket.email = data.email;
      this.ticket.telefono = data.telefono;
      
      if(data.direccion && data.direccion !== null){
        this.ticket.cp = data.direccion.cp;
        this.ticket.calle = data.direccion.calle;
        this.ticket.numero_exterior = data.direccion.no_exterior;
        this.ticket.numero_interior = data.direccion.no_interior;
        this.ticket.colonia = data.direccion.colonia;
        this.ticket.delegacion_municipio = data.direccion.delegacion;
        this.ticket.estado = data.direccion.estado;
      }
    },
    importar_informacion_cliente(data){  
      this.$log.info('cliente buscado importación', data);
      // this.$log.info('acutalizar ticket',data);
      if(data.email !== null)
        this.ticket.email = data.email.valor;
      
      if(data.telefono !==null) 
        this.ticket.telefono = data.telefono.valor; 

      if(data.folio_credito !== null)
        this.ticket.folio_credito = data.folio_credito;        
      
      if(data.direccion !== null){
        this.ticket.cp = data.direccion.cp;
        this.ticket.calle = data.direccion.calle;
        this.ticket.numero_exterior = data.direccion.no_exterior;
        this.ticket.numero_interior = data.direccion.no_interior;
        this.ticket.colonia = data.direccion.colonia;
        this.ticket.delegacion_municipio = data.direccion.delegacion;
        this.ticket.estado = this.$helper.normalizar(data.direccion.estado);
      }
    },
    async mostrar_nueva_info(){
      try {
        this.nueva_direccion = false;
        this.informacion_busqueda = (await apiClientes.obtener_informacion_cliente(this.ticket.cliente_id)).data;
        // this.ticket.cliente_nombre = this.ticket.cliente_nombre;
        this.importar_nuevo_contacto= true;        
      } catch (error) {
        this.$log.info('error',error);
        this.$helper.showAxiosError(error,'Error');           
      }
    },
    importar_nueva_informacion(data){  
      if(data.email !== null)
        this.ticket.email = data.email.valor;
      
      if(data.telefono !==null) 
        this.ticket.telefono = data.telefono.valor;
        
      if(data.folio_credito !== null)
        this.ticket.folio_credito = data.folio_credito;
      
      if(data.direccion !== null){
        this.ticket.cp = data.direccion.cp;
        this.ticket.calle = data.direccion.calle;
        this.ticket.numero_exterior = data.direccion.no_exterior;
        this.ticket.numero_interior = data.direccion.no_interior;
        this.ticket.colonia = data.direccion.colonia;
        this.ticket.delegacion_municipio = data.direccion.delegacion;
        this.ticket.estado = this.$helper.normalizar(data.direccion.estado);
      }
    },
    actualizar_solicitud(data){
      this.ticket.descripcion = data.cliente.descripcion;
    },
    actualizar_clasificacion(data){
      this.ticket.tipo_solicitud = data.tipo_solicitud;
      this.ticket.motivo = data.motivo;
      this.ticket.area = data.area;
      this.ticket.subarea = data.subarea;
    }

  },
}
</script>

<style lang="scss" scoped>
  .input-group-cliente {
    position: relative;

    .buscar_cliente {
      background-color: #f3f3f3;
      width: calc(100% - 30px);
      max-height: 200px;
      padding: 10px 0px;
      border: 1px solid #a7a7a7;
      overflow: auto;
      position: absolute;
      top: 38px;
      left: 15px;
      z-index:100;

      ul {
        list-style: none;
        margin: 0px;
        padding: 0px;

        li {
          padding: 5px 10px;
          border-bottom: solid 1px #a7a7a7;
          cursor: pointer;
        }

        li:first-child {
          cursor: default;
        }

        li:last-child {
          border-bottom: 0px;
        }
      }
    }
  }

  textarea {
    width: 100%;
    height: 200px;
    resize: none;
  }
</style>