<template>
  <div>
    <fieldset>
    <legend>Importar dirección del cliente</legend>
    <div class="row form-group ">
        <label for="cliente" class="col-form-label col-sm-2">Busca un cliente</label>
        <div class="col-sm-6 input-group-cliente">
            <input v-model="busqueda" type="text" name="cliente" id="cliente" class="form-control" autocomplete="off">
            <div v-if="lista_clientes && lista_clientes.data && lista_clientes.data.length > 0" class="buscar_cliente">
                <ul>
                    <li>Selecciona un cliente</li>
                    <li v-for="cliente in lista_clientes.data" :key="cliente.id" @click="buscar_informacion(cliente)">{{ cliente.nombre_completo }}</li>
                </ul>
            </div>
        </div>
        <div class="col-sm-4"><button class="btn btn-info btn-block" :disabled="!busqueda" @click="modal_informacion=true">Importar Información</button></div>
    </div>
    </fieldset>
    
    <Informacion v-if="modal_informacion"
      :informacion="informacion_busqueda"
      @close="modal_informacion=false"
      @informacion="informacion_importada"
      :metodo_contacto="metodo_contacto"
      @nueva_direccion="$emit('nueva_direccion')"
    />

    <CreditosModal v-if="modal_cliente_credito" :creditos="creditos" @close="modal_cliente_credito=false" @credito="credito_seleccionado"/>
    

  </div>
</template>

<script>
import apiClientes from '@/apps/clientes/api/clientes';
import Informacion from "./Informacion";
import CreditosModal from "./CreditosModal";
export default {
  components:{
    Informacion, CreditosModal
  },
  props:{
      ticket:{  
        type: Object,
        required: true,
      },
      metodo_contacto:{
        type:Array,
        required: true,
      }
  },
  data(){
    return{
      cliente:{
        id:null,
        nombre:null,
        direccion:null,
        email:null,
        telefono:null,
        folio_credito:null,
      },
      busqueda:null,
      lista_clientes: [],
      datos_cliente:{},
      modal_informacion: false,
      informacion_busqueda:[],
      creditos:[],
      modal_cliente_credito:false
    }
  },
  methods:{
    async buscar_cliente() {
      try {
        let options = {
          order: 'id',
          limit: 1000,
          search: this.busqueda
        }

        this.lista_clientes = (await apiClientes.listar_usuarios(options)).data;
        // this.$log.info('res', this.lista_clientes);
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    async buscar_informacion(cliente) {
      this.informacion_busqueda = (await apiClientes.obtener_informacion_cliente(cliente.id)).data;
      // this.$log.info('resultado de la busqueda de información del cliente', this.informacion_busqueda);
      if(this.informacion_busqueda.length > 0)
        this.informacion_busqueda.forEach(element => {
          if(element.direcciones.length > 0)
            element.direcciones.map(element => (element.principal === 1) && (this.cliente.direccion = element));
          
          if(element.contactos.length > 0)
            element.contactos.map(element => (element.principal === 1 && element.tipo === 'email') && (this.cliente.email  = element.valor));
           
          if(this.cliente.email == null)
            element.contactos.map(element => (element.tipo === 'email') && (this.cliente.email  = element.valor));

          if(element.contactos.length > 0)
            element.contactos.map(element => (element.tipo.match(/telefono.*/)) && (this.cliente.telefono = element.valor));
          
          if(element.creditos.length > 0)
            this.creditos = element.creditos;
        });

      if(this.creditos.length > 1)
        this.modal_cliente_credito = true;
      else
        this.cliente.folio_credito = this.creditos[0].folio;
      
      this.busqueda = this.informacion_busqueda[0].nombre_completo;
      this.cliente.id = this.informacion_busqueda[0].id;
      this.cliente.nombre = this.informacion_busqueda[0].nombre_completo;
      this.lista_clientes = null;

    },
    informacion_importada(data){
      this.datos_cliente = data;
    },
    credito_seleccionado(data){
      // this.$log.info('credito seleccionado', data);
      this.cliente.folio_credito = data;
    },
    actualizar_cliente(){
      this.$emit('actualizar', this.datos_cliente);
    },
    // ver_informacion(){
    //   this.buscar_cliente();
    //   this.modal_informacion=true;
    // }
  },
  watch: {
    busqueda(val) {
      if(val.length > 0)
        this.buscar_cliente();
    },
    datos_cliente:{
      handler(){
          this.actualizar_cliente();
      },
      deep:true
    },
    cliente:{
      handler(){
        this.$emit('cliente', this.cliente);
      },
      deep:true,
    }    
  },
  // mounted(){
  //   this.busqueda = this.ticket.cliente_nombre;

  // }
}
</script>

<style lang="scss" scoped>
  .input-group-cliente {
    position: relative;

    .buscar_cliente {
      background-color: #f3f3f3;
      width: calc(100% - 30px);
      max-height: 200px;
      padding: 10px 0px;
      border: 1px solid #a7a7a7;
      overflow: auto;
      position: absolute;
      top: 38px;
      left: 15px;
      z-index:100;

      ul {
        list-style: none;
        margin: 0px;
        padding: 0px;

        li {
          padding: 5px 10px;
          border-bottom: solid 1px #a7a7a7;
          cursor: pointer;
        }

        li:first-child {
          cursor: default;
        }

        li:last-child {
          border-bottom: 0px;
        }
      }
    }
  }
</style>