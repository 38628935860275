<template>
    <div>
        <div class="row form-group">
            <template v-if="ticket.origen==1 || ticket.id!==null">
                <template v-if="ticket.subarea==null || ticket.area==null || ticket.motivo==null || ticket.tipo_solicitud==null">
                    <div class="col-sm-12">
                        <fieldset>
                            <legend>Solicitud del cliente</legend>
                                <div class="row form-group">
                                    <label for="descripcion" class="col-form-label col-sm-2">Descripción de la consulta/solicitud</label>
                                    <div class="col-sm-10">
                                        <textarea :disabled="ticket.origen==1" v-model="ticket.descripcion" name="descripcion" id="descripcion" cols="30" rows="5" class="form-control"></textarea>
                                    </div>
                                </div>              
                        </fieldset>
                    </div>
                </template>
            </template>
            <div class="col-sm-6">
                <fieldset>
                    <legend>Clasificación del ticket</legend>
                    <div class="row form-group"> 
                        <label for="estado" class="col-form-label col-sm-4"> Tipo de solicitud </label>
                        <div class="col-sm-8">
                            <select v-model="clasificacion.tipo_solicitud" name="direccion.estado" id="direccion.estado" class="form-control" >
                                <option value="null">Selecciona una opción</option>
                                <option v-for="tipo in tipos_solicitud" :key="tipo.id" :value="tipo.nombre">{{ tipo.nombre }}</option>
                            </select>
                        </div>  
                    </div>
                    <div class="row form-group"> 
                        <label for="estado" class="col-form-label col-sm-4 col-mb-4 col-lg-s"> Motivo del ticket </label>
                        <div class="col-sm-8">
                            <div class="input-group">
                                <select v-model="clasificacion.motivo" name="direccion.estado" id="direccion.estado" class="form-control" >
                                    <option value="null">Selecciona una opción</option>
                                    <option v-for="motivo in motivos_ticket" :key="motivo.id" :value="motivo.nombre">{{ motivo.nombre }}</option>
                                </select>                                
                                <div class="input-group-addon input-group-button">
                                    <button type="button" id="show-contact-modal-button" class="btn btn-success ml-2" @click="modal_motivo=true">Agregar Motivo</button>
                                </div>
                            </div>
                        </div>  
                    </div>                                             
                </fieldset>
            </div>
            <div class="col-sm-6">
                <fieldset>
                    <legend>Asigna el ticket a un área</legend>
                    <div class="row form-group"> 
                        <label for="estado" class="col-form-label col-sm-4"> Áreas </label>
                        <div class="col-sm-8">
                            <select v-model="clasificacion.area" name="direccion.estado" id="direccion.estado" class="form-control" >
                                <option value="null">Selecciona una opción</option>
                                <option v-for="area in areas_bpb" :key="area.id" :value="area.nombre">{{ area.nombre }}</option>
                            </select>
                        </div>  
                    </div>
                    <div class="row form-group"> 
                        <label for="estado" class="col-form-label col-sm-4"> Subáreas </label>
                        <div class="col-sm-8">
                            <select v-model="clasificacion.subarea" name="direccion.estado" id="direccion.estado" class="form-control" >
                                <option value="null">Selecciona una opción</option>
                                <option v-for="subarea in subareas_bpb" :key="subarea.id" :value="subarea.nombre">{{ subarea.nombre }}</option>
                            </select>
                        </div>  
                    </div>
                </fieldset>
            </div>          
        </div>		    
        <Motivo v-if="modal_motivo" @close="modal_motivo=false" @actualizar="$emit('actualizar_catalogos')"/>
    </div>
</template>

<script>
import Motivo from "./Administrar/Catalogos/Motivo";
export default {
    components:{
        Motivo
    },
    props:{
        tipos_solicitud:{
            type:Array,
            default(){
                return [];
            }
        },
        motivos_ticket:{
            type:Array,
            default(){
                return [];
            }
        },
        areas_bpb:{
            type:Array,
            default(){
                return [];
            }   
        },
        subareas_bpb:{
            type:Array,
            default(){
                return [];
            }
        },
        ticket:{
            type:Object,
            default(){
                return {};
            }
        }
    },
    data(){
        return{
            clasificacion:{
                tipo_solicitud:null,
                motivo:null,
                area:null,
                subarea:null,
            },
            modal_motivo:false
        }
    },
    methods:{
        actualizar_clasificacion(){
            this.$emit('actualizar_clasificacion', this.clasificacion);
        },
        test(){
            this.$log.info('clasificación ', this.ticket);
        }
    },
    watch:{
        clasificacion:{
            handler(){
                this.actualizar_clasificacion();
            },
            deep:true
        }
    },
    // computed:{
    //     mostrar_solicitud(){

    //     },
    // },
    beforeMount(){
        this.clasificacion.tipo_solicitud = this.ticket.tipo_solicitud;
        this.clasificacion.motivo = this.ticket.motivo;
        this.clasificacion.area = this.ticket.area;
        this.clasificacion.subarea = this.ticket.subarea;
        this.test();
    }
}
</script>

<style>

</style>